import axios from 'axios';
import jwtConfig from './jwtConfig';

export default class JwtService {
  jwtConfig = jwtConfig;
  isAlreadyFetchingAccessToken = false;
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    axios.interceptors.request.use(
      (config) => {
        const accessToken = this.getAccessToken();
        if (accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { config, response: { status } = {} } = error;
        const originalRequest = config;

        if (status === 401 && config.url.includes('/refresh')) {
          const { data } = await this.login();
          window.location.href = data.url;
        } else if (status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken()
              .then((r) => {
                this.isAlreadyFetchingAccessToken = false;
                const accessToken = r.data.access_token;
                this.setAccessToken(accessToken);
                this.onAccessTokenFetched(accessToken);
              })
              .catch((err) => {
                console.log(err);
              });
          }

          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }
  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
  }
  addSubscriber(callback) {
    this.subscribers.push(callback);
  }
  getAccessToken() {
    return localStorage.getItem(this.jwtConfig.storageAccessTokenKey);
  }
  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshToken);
  }
  setAccessToken(accessToken) {
    localStorage.setItem(this.jwtConfig.storageAccessTokenKey, accessToken);
  }
  setRefreshToken(refreshToken) {
    localStorage.setItem(this.jwtConfig.storageRefreshToken, refreshToken);
  }
  removeAccessToken() {
    localStorage.removeItem(this.jwtConfig.storageAccessTokenKey);
  }
  removeRefreshToken() {
    localStorage.removeItem(this.jwtConfig.storageRefreshToken);
  }
  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }
  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, { refreshToken: this.getRefreshToken() });
  }
  logout() {
    this.removeAccessToken();
    this.removeRefreshToken();
  }
}
