import { useEffect } from 'react';
import jwt from '../auth';
import { isUserLoggedIn } from '../auth';

import OrderTable from '../components/OrderTable';
import OrderTableCode from '../components/OrderTableCode';
import CodeTable from '../components/CodeTable';
import ProductTable from '../components/ProductTable';
import OrderImportRaw from '../components/OrderImportRaw';

import { orderStore, productStore } from '../store';
import { codeStore } from '../store/code';

const Home = () => {
  const { syncOrderList } = orderStore();
  const { syncProductList } = productStore();
  const { syncCodeList } = codeStore();

  useEffect(() => {
    const func = async () => {
      await syncOrderList();
      await syncProductList();
      await syncCodeList();
      if (!(await isUserLoggedIn())) {
        const { data } = await jwt.login();
        window.location.href = data.url;
      }
    };
    func();
  }, []);

  return (
    <>
      <OrderImportRaw />
      <hr />
      <OrderTable />
      <hr />
      <OrderTableCode />
      <hr />
      <CodeTable />
      <hr />
      <ProductTable />
      {/* <ProductCategory /> */}
    </>
  );
};

export default Home;
