import { useState } from 'react';
import { productStore } from '../store';

const ProductTable = () => {
  const { productList, createBlankProduct, updateProduct, removeProduct } = productStore();

  const [productId, setProductId] = useState('');

  const onProductChange = (productId, attribute, value) => updateProduct(productId, attribute, value);
  const onRemoveClick = (productId) => removeProduct(productId);

  const onProductIdChange = (e) => setProductId(e.target.value);
  const onCreateProductClick = () => {
    createBlankProduct(productId);
    setProductId('');
  };

  const tableHeader = ['productId', 'productName', 'quantity'];
  return (
    <>
      <div>
        New Product ID: <input type="text" onChange={onProductIdChange} value={productId} />
        <button onClick={(e) => onCreateProductClick()}>CREATE</button>
      </div>
      <table>
        <thead>
          <tr>
            {tableHeader.map((h, i) => (
              <th key={i}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {productList.map((product, i) => (
            <tr key={i}>
              {tableHeader.map((k, i) => (
                <td key={i}>
                  <input type="text" onChange={(e) => onProductChange(product['productId'], k, e.target.value)} value={product[k]} />
                </td>
              ))}
              <td>
                <button onClick={(e) => onRemoveClick(product['productId'])}>X</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ProductTable;
