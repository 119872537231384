import create from 'zustand';
import API from '../api';

export const productStore = create((set, get) => ({
  productList: [],
  syncProductList: async () => {
    const productList = await API.getProductList();
    set({ productList: productList });
  },
  getProductList: () => get().productList,
  createProductList: async (productList) => {
    await Promise.all(
      productList.map(async (product) => {
        await API.createProduct(product);
      })
    );
    set((state) => state.syncProductList());
  },
  createProduct: async (product) => {
    await API.createProduct(product);
    set((state) => state.syncProductList());
  },
  createBlankProduct: async (productId) => {
    await API.createProduct({
      productId,
      productName: 'noname',
      quantity: 0,
    });
    set((state) => state.syncProductList());
  },
  updateProduct: async (productId, attribute, value) => {
    const productList = get().productList;
    const idx = productList.findIndex((product) => product['productId'] === productId);
    productList[idx][attribute] = value;
    await API.updateProduct(productList[idx]);
    set((state) => state.syncProductList());
  },
  removeProduct: async (productId) => {
    await API.removeProduct(productId);
    set((state) => state.syncProductList());
  },
  getProductIdByName: (productName) => {
    const productList = get().productList;
    const idx = productList.findIndex((product) => product['productName'] === productName);
    if (idx === -1) return 0;
    return productList[idx]['productId'];
  },
  getQuantityByName: (productName) => {
    const productList = get().productList;
    const idx = productList.findIndex((product) => product['productName'] === productName);
    if (idx === -1) return 0;
    return productList[idx]['quantity'];
  },
  getProductNameById: (productId) => {
    const productList = get().productList;
    const idx = productList.findIndex((product) => product['productId'] === productId);
    return productList[idx]['productName'];
  },
}));

export const orderStore = create((set, get) => ({
  orderList: [],
  orderListCode: [],
  orderListSmm: [],
  syncOrderList: async () => {
    const orderList = await API.getOrderList();
    const orderListCode = await API.getOrderList('/code');
    set({ orderList: orderList });
    set({ orderListCode: orderListCode });
  },
  getOrderList: () => get().orderList,
  createOrderList: async (orderList) => {
    await Promise.all(
      orderList.map(async (order) => {
        await API.createOrder(order);
      })
    );
    set((state) => state.syncOrderList());
  },
  createOrder: async (order) => {
    await API.createOrder(order);
    set((state) => state.syncOrderList());
  },
  createBlankOrder: async (orderId) => {
    await API.createOrder({
      orderId,
      productId: 0,
      quantity: 0,
      target: 'None',
      smmId: '0',
      status: 'Ready',
    });
    set((state) => state.syncOrderList());
  },
  updateOrder: async (orderId, attribute, value) => {
    const orderList = get().orderList;
    const idx = orderList.findIndex((order) => order['orderId'] === orderId);
    orderList[idx][attribute] = value;
    await API.updateOrder(orderList[idx]);
    set((state) => state.syncOrderList());
  },
  removeOrder: async (orderId) => {
    await API.removeOrder(orderId);
    set((state) => state.syncOrderList());
  },
  checkRealsiteOrder: async (smmId) => {
    await API.checkRealsiteOrder(smmId);
    set((state) => state.syncOrderList());
  },
  createRealsiteOrder: async (order) => {
    await API.createRealsiteOrder(order);
    set((state) => state.syncOrderList());
  },
}));
