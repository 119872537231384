import './App.css';

import { useEffect } from 'react';
import jwt from './auth';
import { isUserLoggedIn } from './auth';

import { Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import Token from './views/Token';

const App = () => {
  useEffect(() => {
    const func = async () => {
      if (!(await isUserLoggedIn())) {
        const { data } = await jwt.login();
        window.location.href = data.url;
      }
    };
    func();
  }, []);
  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/token" element={<Token />} />
      </Routes>
    </div>
  );
};

export default App;
