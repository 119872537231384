import axios from 'axios';
import { Fragment, useEffect } from 'react';

const SetTokenInfo = () => {
  useEffect(async () => {
    const query = new URLSearchParams(window.location.search);
    const accessToken = query.get('token');
    const refreshToken = query.get('refreshToken');

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    const { data } = await axios('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    localStorage.setItem('userData', JSON.stringify(data));
    window.location.href = '/';
  }, []);
  return <></>;
};

const Token = () => {
  return (
    <Fragment>
      <SetTokenInfo />
    </Fragment>
  );
};

export default Token;
