import create from 'zustand';
import CODE from '../api/code';

export const codeStore = create((set, get) => ({
  codeList: [],
  syncCodeList: async () => {
    const codeList = await CODE.getCodeList();
    set({ codeList: codeList });
  },
  getCodeList: () => get().codeList,
  createCodeList: async (codeNumberList, productId) => {
    await Promise.all(
      codeNumberList.map(async (codeNumber) => {
        await CODE.createCode({ codeNumber, productId });
      })
    );
    set((state) => state.syncCodeList());
  },
  createCode: async (code) => {
    await CODE.createCode(code);
    set((state) => state.syncCodeList());
  },
  updateCode: async (code, attribute, value) => {
    const codeList = get().codeList;
    const idx = codeList.findIndex((code) => code['code'] === code);
    codeList[idx][attribute] = value;
    await CODE.updateCode(codeList[idx]);
    set((state) => state.syncCodeList());
  },
  deleteCode: async (codeNumber) => {
    await CODE.deleteCode(codeNumber);
    set((state) => state.syncCodeList());
  },
  sendCode: async ({ orderId, productId, target, ...args }) => {
    await CODE.sendCode({ orderId, productId, target });
    set((state) => state.syncCodeList());
  },
  checkRealsiteCode: async (smmId) => {
    await CODE.checkRealsiteCode(smmId);
    set((state) => state.syncCodeList());
  },
  createRealsiteCode: async (code) => {
    await CODE.createRealsiteCode(code);
    set((state) => state.syncCodeList());
  },
}));
