import { orderStore } from '../store';
import { codeStore } from '../store/code';

const OrderTable = () => {
  const { orderListCode } = orderStore();
  const { sendCode } = codeStore();

  const tableHeader = ['orderId', 'productId', 'quantity', 'target'];
  const onSendClick = (order) => sendCode(order);
  return (
    <>
      <h1>Order Table (CODE)</h1>
      <table>
        <thead>
          <tr>
            {tableHeader.map((h, i) => (
              <th key={i}>{h}</th>
            ))}
            <th>send</th>
          </tr>
        </thead>
        <tbody>
          {orderListCode.map((order, i) => (
            <tr key={i}>
              {tableHeader.map((k, i) => (
                <td key={i}>{order[k]}</td>
              ))}
              <td>
                <button
                  disabled={order.status === 'Completed'}
                  onClick={(e) => {
                    onSendClick(order);
                  }}>
                  SEND
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default OrderTable;
