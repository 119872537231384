import axios from 'axios';

const API_URL = process.env.REACT_APP_NOZ_API;

const tryCatchHandler = (fn) => {
  return async (...args) => {
    try {
      return await fn(...args);
    } catch (err) {
      alert(err.response.data.message);
      return [];
    }
  };
};

const createCode = tryCatchHandler(async (data) => {
  await axios.post(`${API_URL}/code`, data);
});
const getCodeList = async () => {
  try {
    const res = await axios.get(`${API_URL}/codes`);
    return res.data;
  } catch (err) {
    return [];
  }
};
const updateCode = tryCatchHandler(async (data) => {
  await axios.put(`${API_URL}/code`, data);
});
const deleteCode = tryCatchHandler(async (codeNumber) => {
  await axios.delete(`${API_URL}/code/${codeNumber}`);
});
const sendCode = tryCatchHandler(async (data) => {
  await axios.post(`${API_URL}/code/send/email`, data);
});

export default {
  createCode,
  getCodeList,
  updateCode,
  deleteCode,
  sendCode,
};
