import { useState } from 'react';
import { orderStore } from '../store';

const OrderTable = () => {
  const { orderList, updateOrder, removeOrder, createBlankOrder, checkRealsiteOrder, createRealsiteOrder } = orderStore();
  const [orderId, setOrderId] = useState('');

  const onOrderChange = (orderId, attribute, value) => updateOrder(orderId, attribute, value);
  const onCreateRealsiteOrder = async (order) => await createRealsiteOrder(order);
  const onCheckRealsiteOrder = async (smmId) => await checkRealsiteOrder(smmId);
  const onCheckRealsiteOrderAll = async () => await Promise.all(orderList.map(async (order) => await checkRealsiteOrder(order['smmId'])));
  const onRemoveClick = (orderId) => removeOrder(orderId);

  const onOrderIdChange = (e) => setOrderId(e.target.value);
  const onCreateOrderClick = () => {
    createBlankOrder(orderId);
    setOrderId('');
  };

  const tableHeader = ['orderId', 'productId', 'quantity', 'target', 'smmId', 'status'];
  return (
    <>
      <div>
        New Order ID: <input type="text" onChange={onOrderIdChange} value={orderId} />
        <button onClick={(e) => onCreateOrderClick()}>CREATE</button>
      </div>
      <table>
        <thead>
          <tr>
            {tableHeader.map((h, i) => (
              <th key={i}>{h}</th>
            ))}
            <th>remove</th>
            <th>check</th>
            <th>order</th>
          </tr>
        </thead>
        <tbody>
          {orderList.map((order, i) => (
            <tr key={i}>
              {tableHeader.map((k, i) => (
                <td key={i}>
                  <input type="text" onChange={(e) => onOrderChange(order['orderId'], k, e.target.value)} value={order[k]} />
                </td>
              ))}
              <td>
                <button onClick={(e) => onRemoveClick(order['orderId'])}>X</button>
              </td>
              <td>
                <button onClick={(e) => onCheckRealsiteOrder(order['smmId'])}>Check</button>
              </td>
              <td>
                <button onClick={(e) => onCreateRealsiteOrder(order)}>Create Order</button>
              </td>
              <td>
                <button>
                  <a href={order['target']} target="_blank">
                    go to target
                  </a>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button onClick={(e) => onCheckRealsiteOrderAll()}>Check All</button>
      </div>
    </>
  );
};

export default OrderTable;
