import { useState } from 'react';
import { codeStore } from '../store/code';

const CodeTable = () => {
  const { codeList, createCodeList, deleteCode } = codeStore();
  const [newCodeNumbers, setNewCodeNumbers] = useState('');
  const [productId, setProductId] = useState(0);

  const tableHeader = ['codeNumber', 'productId'];
  const onNewKeysChange = (e) => setNewCodeNumbers(e.target.value);
  const onProductIdChange = (e) => setProductId(e.target.value);
  const onCreateNewCodes = () => {
    createCodeList(newCodeNumbers.split('\n'), productId);
    setNewCodeNumbers('');
  };

  return (
    <>
      <h1>Code Table</h1>
      <div>
        <div>
          Product ID: <input type="text" onChange={onProductIdChange} value={productId} />
        </div>
        <div>
          <textarea onChange={onNewKeysChange} value={newCodeNumbers} />
        </div>
        <div>
          <button onClick={onCreateNewCodes}>CREATE</button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {tableHeader.map((h, i) => (
              <th key={i}>{h}</th>
            ))}
            <th>availability</th>
            <th>delete</th>
          </tr>
        </thead>
        <tbody>
          {codeList.map((code, i) => (
            <tr key={i}>
              {tableHeader.map((k, i) => (
                <td key={i}>{code[k]}</td>
              ))}
              <td key={i}>{code['availability'] ? 'True' : 'False'}</td>
              <td>
                <button onClick={() => deleteCode(code.codeNumber)}>delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CodeTable;
