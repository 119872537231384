import { useState } from 'react';

import { orderStore } from '../store';
import { productStore } from '../store';

const OrderRawImport = () => {
  const { orderList, createOrder } = orderStore();
  const { getProductIdByName, getQuantityByName } = productStore();
  const [newOrderList, setNewOrderList] = useState([]);

  const filterExistsOrder = (newOrderList) => {
    return newOrderList.filter((newOrder) => {
      let orderExists = orderList.findIndex((order) => order.orderId === newOrder.orderId);
      if (orderExists !== -1) alert(`${newOrder.orderId} exists`);
      return orderExists === -1; // If not exists, add it
    });
  };

  const onNewOrderListClick = async (newOrderList) => {
    const orderList = filterExistsOrder(newOrderList);
    await Promise.all(
      orderList.map(async (order) => {
        await createOrder(order);
      })
    );
  };

  const onOrderListChange = (e) => {
    const rawOrderList = e.target.value;
    const newOrderList = rawOrderList.split('\n').map((o) => {
      const [orderId, productName, orderCount, target] = o.split('\t');
      const productId = getProductIdByName(productName);
      const quantity = getQuantityByName(productName) * String(Number(orderCount));

      return {
        orderId,
        productId,
        quantity,
        target,
      };
    });
    setNewOrderList(newOrderList);
  };
  return (
    <>
      <textarea placeholder="Import From Smartstore" rows="3" cols="100" onChange={onOrderListChange} />
      <button onClick={() => onNewOrderListClick(newOrderList)}>Import</button>
    </>
  );
};

export default OrderRawImport;
