const API_URL = process.env.REACT_APP_NOZ_API;

export default {
  loginEndpoint: `${API_URL}/login`,
  refreshEndpoint: `${API_URL}/refresh`,

  tokenType: 'Bearer',
  storageAccessTokenKey: 'accessToken',
  storageRefreshToken: 'refreshToken',
};
