import axios from 'axios';

const API_URL = process.env.REACT_APP_NOZ_API;

export const getProductList = async (service = '') => {
  try {
    const res = await axios.get(`${API_URL}/products${service}`);
    return res.data;
  } catch (err) {
    return [];
  }
};

export const createProduct = async (product) => {
  try {
    await axios.post(`${API_URL}/product`, product);
  } catch (e) {
    console.log(e);
  }
};

export const updateProduct = async (product) => {
  try {
    await axios.put(`${API_URL}/product`, product);
  } catch (err) {
    console.log(err);
  }
};

export const removeProduct = async (productId) => {
  try {
    await axios.delete(`${API_URL}/product/${productId}`);
  } catch (err) {
    console.log(err);
  }
};

export const getOrderList = async (service = '') => {
  try {
    const res = await axios.get(`${API_URL}/orders${service}`);
    return res.data;
  } catch (err) {
    return [];
  }
};

export const createOrder = async (order) => {
  try {
    await axios.post(`${API_URL}/order`, order);
  } catch (e) {
    console.log(e);
  }
};

export const updateOrder = async (order) => {
  try {
    await axios.put(`${API_URL}/order`, order);
  } catch (err) {
    console.log(err);
  }
};

export const removeOrder = async (orderId) => {
  try {
    await axios.delete(`${API_URL}/order/${orderId}`);
  } catch (err) {
    console.log(err);
  }
};

export const checkRealsiteOrder = async (smmId) => {
  try {
    const res = await axios.get(`${API_URL}/realsite/${smmId}`);
    return res.data;
  } catch (err) {
    return [];
  }
};

export const createRealsiteOrder = async (order) => {
  try {
    const res = await axios({
      method: 'post',
      url: `${API_URL}/realsite`,
      headers: { 'Content-Type': 'application/json' },
      data: order,
    });
    return res.data;
  } catch (err) {
    return [];
  }
};

export default {
  getProductList,
  createProduct,
  updateProduct,
  removeProduct,
  getOrderList,
  createOrder,
  updateOrder,
  removeOrder,
  checkRealsiteOrder,
  createRealsiteOrder,
};
